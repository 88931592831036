import { useLocation, useNavigate } from 'react-router-dom';
import { verifyJwt } from '../auth/auth/use-auth';
import { useEffect } from 'react';

const Auth = ({ setIsAuthenticated }) => {
    //Hpacs에서 parameter로 오는 jwt를 태우는 경우 '주소/auth?jwt=토큰'
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const hjwt = params.get('jwt');
    const navigate = useNavigate();

    useEffect(() => {
        checkJwt();
    }, []);

    const checkJwt = async () => {
        if (hjwt) {
            const result = await verifyJwt(hjwt);
            if (result.data) {
                localStorage.setItem('hpacsJwt', hjwt);
            }
            setIsAuthenticated(result.data);
        } else {
            setIsAuthenticated(false);
        }
        navigate('/');
    };
};

export default Auth;
