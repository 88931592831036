import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Navigate,
    Route,
    Routes,
} from 'react-router-dom';
import { verifyJwt } from '../auth/auth/use-auth';
import { Spinner } from 'react-bootstrap';
import Login from '../pages/login/login';
import HpacsManual from '../pages/hpacs_manual/hpacs_manual';
import Auth from '../pages/auth';

export const AppRouter = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(null); // null로 초기화
    const jwt = localStorage.getItem('hpacsJwt'); //help사이트 login의 경우만 해당

    useEffect(() => {
        checkJwt();
    }, []);

    const checkJwt = async () => {
        if (jwt) {
            const result = await verifyJwt(jwt);
            setIsAuthenticated(result.data);
        } else {
            setIsAuthenticated(false);
        }
    };

    if (isAuthenticated === null) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    return (
        <Router>
            <Routes>
                <Route
                    path="/auth"
                    element={<Auth setIsAuthenticated={setIsAuthenticated} />}
                />
                <Route
                    path="/manual"
                    element={
                        isAuthenticated ? <HpacsManual /> : <Navigate to="/" />
                    }
                />
                <Route
                    path="/"
                    element={
                        !isAuthenticated ? (
                            <Login setIsAuthenticated={setIsAuthenticated} />
                        ) : (
                            <Navigate to="/manual" />
                        )
                    }
                />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </Router>
    );
};
