import {Badge, Container} from 'react-bootstrap';
import './hpacs-manual.css';
import logo from '../../images/logo.png';
import hpacsCloud from '../../images/1.png';
import hpacsLogin from '../../images/2.png';
import hstoreLogin from '../../images/3.png';
import windowMsg from '../../images/4.png';
import windowIcon from '../../images/5.png';
import setting from '../../images/6.png';
import hpacs from '../../images/7.png';

const HpacsManual = () => {
    const pdfApiUrls = {
        HPACS_Radiology: "/help/download/manual?product=hpacs&type=radiology&location=en",
        HPACS_Technician: "/help/download/manual?product=hpacs&type=tech&location=en",
        "HPACS-Admin": "/help/download/manual?product=hpacs-admin&location=en",
        HStore: "/help/download/manual?product=hstore&location=en",
    };
    const downloadPdf = async (type) => {
        let apiUrl = "";
        if(type === "HPACS_Radiology") {
            apiUrl = pdfApiUrls.HPACS_Radiology;
        } else if(type === "HPACS_Technician") {
            apiUrl = pdfApiUrls.HPACS_Technician;
        } else if(type === "HPACS-Admin") {
            apiUrl = pdfApiUrls["HPACS-Admin"];
        } else if(type === "HStore") {
            apiUrl = pdfApiUrls.HStore;
        } else return;

        window.open(process.env.REACT_APP_ADMIN_API_BASE_URL+apiUrl, '_blank')
    };

    return (
        <Container
            style={{
                maxWidth: '100%',
                padding: 0,
                backgroundColor: '#dedede',
            }}
        >
            <div style={{ padding: '20px 40px' }}>
                <img src={logo} alt="Healthhub logo" />
            </div>
            <div className={'title-container'}>
                <div className={'title'}>
                    <span style={{ color: '#315689' }}>HPACS</span> Help
                </div>
                <div className={'circle-container'}>
                    <div className={'circle'}></div>
                    <div className={'circle'}></div>
                    <div className={'circle'}></div>
                </div>
            </div>
            {/*<div className={'step-container'}>*/}
            {/*    <div className={'step-title'}>*/}
            {/*        Verify the login using the registered account.*/}
            {/*    </div>*/}
            {/*    <div className={'image-container'}>*/}
            {/*        <div className={'image-wrapper'}>*/}
            {/*            <img*/}
            {/*                src={hpacsCloud}*/}
            {/*                alt="hpacs cloud"*/}
            {/*                className={'image-size'}*/}
            {/*            />*/}
            {/*            <div className={'desc'}>*/}
            {/*                Move to the{' '}*/}
            {/*                <a*/}
            {/*                    href="https://www.hpacs.cloud"*/}
            {/*                    target="_blank"*/}
            {/*                    rel="hpacs website noreferrer"*/}
            {/*                    style={{*/}
            {/*                        color: '#266897',*/}
            {/*                        textDecoration: 'none',*/}
            {/*                    }}*/}
            {/*                >*/}
            {/*                    https://www.hpacs.cloud*/}
            {/*                </a>{' '}*/}
            {/*                service.*/}
            {/*                <br />*/}
            {/*                Click on the "Login" button at the top to navigate*/}
            {/*                to the login page.*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className={'image-wrapper'}>*/}
            {/*            <img*/}
            {/*                src={hpacsLogin}*/}
            {/*                alt="hpacs login"*/}
            {/*                className={'image-size'}*/}
            {/*            />*/}
            {/*            <div className={'desc'}>*/}
            {/*                Log in with your registered account.*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className={'text-wrapper'}>*/}
            {/*        - Upon successful login, proceed with the configuration*/}
            {/*        required for uploading study.*/}
            {/*        <br />*/}
            {/*        - In the event of a login error, kindly reach out to the*/}
            {/*        administrator.*/}
            {/*        <br /> &nbsp;&nbsp;(You may find the administrator's contact*/}
            {/*        details at the bottom.)*/}
            {/*        <br />*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className={'step-container2'}>
                <div className={'step-title2'}>
                    How to Upload a Study with an{' '}
                    <span style={{ color: '#172c4a', fontWeight: 'bold' }}>
                        HPACS
                    </span>{' '}
                    Account
                </div>
                <div className={'image-container'}>
                    <div className={'image-wrapper'}>
                        <div style={{ padding: '15px' }}>
                            [ HStore Installation Specifications ]<br />
                            <span style={{ fontWeight: 'bold' }}>
                                Software Operating Environment:
                            </span>
                            <br />
                            Internet connection is required. <br />
                            Operating System: Microsoft Windows 10, 32/64 bit.
                            <br />
                            For Windows 7 and below, manual installation is
                            required: .Net Framework: v4.0 or higher (v4.5 or
                            higher recommended). <br />
                            <span
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                }}
                            >
                                Hardware Operating Environment:
                            </span>
                            <br />
                            Recommended Specifications:
                            <br /> CPU: Intel Core i5 or higher. <br />
                            Memory: 8GB or higher. <br />
                            GPU: Integrated graphics card can be used.
                            <br />
                            Storage: SSD is recommended with at least 20GB of
                            available space.
                            <br /> ODD: Required.
                        </div>
                        <div className={'desc2'}>
                            To integrate with the modality, installation is
                            required. You can use either the PC in the modality
                            room or your personal PC. Please refer to the manual
                            for installation instructions.
                        </div>
                    </div>
                    <div className={'image-wrapper'}>
                        <img
                            src={hstoreLogin}
                            alt="hstore login"
                            className={'image-size'}
                        />
                        <div className={'desc2'}>
                            Login using the registered Email and Password in
                            Hpacs.
                        </div>
                    </div>
                    <div className={'image-wrapper'}>
                        <img
                            src={windowMsg}
                            alt="window message"
                            className={'image-size'}
                        />
                        <img
                            src={windowIcon}
                            alt="window hstore icon"
                            className={'image-size2'}
                        />
                        <div className={'desc2'}>
                            Upon successful login, a Windows notification will
                            be displayed, confirming the service start message.
                            <br />
                            The HStore program is displayed as a Tray Icon
                        </div>
                    </div>
                    <div className={'image-wrapper'}>
                        <img
                            src={setting}
                            alt="hstore setting"
                            className={'image-size'}
                        />
                        <div className={'desc2'}>
                            Upon accessing the HStore Settings menu, you will be
                            able to verify the information necessary for
                            configuring the modality. <br />
                            Please configure the aeTitle, Host, and Port on the
                            modality.
                            <br />
                            (If you are unsure about the configuration process,
                            kindly seek assistance from the modality engineer.)
                        </div>
                    </div>
                    <div className={'image-wrapper'}>
                        <img src={hpacs} alt="hpacs" className={'image-size'} />
                        <div className={'desc2'}>
                            Access HPACS to verify the study information.
                        </div>
                    </div>
                </div>
            </div>
            <div className={'box-container'}>
                <div
                    style={{
                        minWidth: '25vh',
                        minHeight: '100%',
                    }}
                >
                    <i
                        className="bi bi-arrow-up-left-circle"
                        style={{
                            fontSize: '32px',
                            color: '#787878',
                            display: 'flex',
                            justifyContent: 'center',
                            marginBottom: '25px',
                        }}
                    ></i>

                    <h5 className={'box-title'}>Access Links to Services</h5>
                    <div style={{ fontSize: '15px', }}>
                        <div>
                            <a
                                href="https://www.hpacs.cloud/login"
                                target="_blank"
                                rel="hpacs website noreferrer"
                                style={{
                                    color: '#787878',
                                    textDecoration: 'none',
                                }}
                            >
                                <Badge bg="primary" className={'manual-content'}>
                                    <i className="bi bi-link-45deg"/>
                                    HPACS
                                </Badge>
                            </a>
                        </div>
                        <div>
                            <a
                                href="https://admin.hpacs.cloud/#/login?redirect=%2Fhome"
                                target="_blank"
                                rel="hpacs website noreferrer"
                                style={{
                                    color: '#787878',
                                    textDecoration: 'none',
                                }}
                            >
                                <Badge bg="primary" className={'manual-content'}>
                                    <i className="bi bi-link-45deg"/>
                                    HPACS-Admin
                                </Badge>
                            </a>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        minWidth: '25vh',
                        minHeight: '100%',
                    }}
                >
                    <i
                        className="bi bi-clipboard-plus"
                        style={{
                            fontSize: '32px',
                            color: '#787878',
                            display: 'flex',
                            justifyContent: 'center',
                            marginBottom: '25px',
                        }}
                    ></i>
                    <h5 className={'box-title'}>Program</h5>
                    <div style={{ fontSize: '15px', }}>
                        <div>
                            <a
                                href="https://drive.google.com/file/d/1JtwKT1ydkeGlucmJ66Y45iZ4Cmsyca7M/view?usp=drive_link"
                                target="_blank"
                                rel="hpacs website noreferrer"
                                style={{
                                    color: '#787878',
                                    textDecoration: 'none',
                                }}
                            >
                                <Badge bg="info" className={'manual-content'}>
                                    <i className="bi bi-download"/>
                                    HStore
                                </Badge>
                            </a>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        minWidth: '25vh',
                        minHeight: '100%',
                    }}
                >
                    <i
                        className="bi bi-card-list"
                        style={{
                            fontSize: '32px',
                            color: '#787878',
                            display: 'flex',
                            justifyContent: 'center',
                            marginBottom: '20px',
                        }}
                    ></i>

                    <h5 className={'box-title'}>Service Manual</h5>
                    <div style={{ fontSize: '15px', }}>
                        <div>
                            <Badge bg="danger" className={'manual-content'} onClick={() => downloadPdf('HPACS_Radiology')}>
                                <i className="bi bi-filetype-pdf"/>
                                HPACS Radiology
                            </Badge>
                        </div>
                        <div>
                            <Badge bg="danger" className={'manual-content'} onClick={() => downloadPdf('HPACS_Technician')}>
                                <i className="bi bi-filetype-pdf"/>
                                HPACS Technician
                            </Badge>
                        </div>
                        <div>
                            <Badge bg="danger" className={'manual-content'} onClick={() => downloadPdf('HPACS-Admin')}>
                                <i className="bi bi-filetype-pdf"/>
                                HPACS-Admin
                            </Badge>
                        </div>
                        <div>
                            <Badge bg="danger" className={'manual-content'} onClick={() => downloadPdf('HStore')}>
                                <i className="bi bi-filetype-pdf"/>
                                HStore
                            </Badge>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'footer'}>
                <div style={{ marginBottom: '10px' }}>
                    <img src={logo} alt="Healthhub logo" />
                </div>
                <div style={{ margin: '5px 0', fontWeight: 'bold' }}>
                    US Corporation
                </div>
                <div>(Office phone number) +1(201) 575 - 5954</div>
                <div>
                    (Email) hh-marketing@healthhub.kr /
                    william.bh.lee@healthhub.kr
                </div>
                <div>(Address) 1055 Palisade Ave Fort Lee, NJ 07024, U.S.A</div>
            </div>
        </Container>
    );
};

export default HpacsManual;
