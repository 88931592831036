import { api } from '../../api/config';
import { apiUrls } from '../../api/api-urls';

export const authLogin = async ({ email, password }) => {
    const { data } = await api.post(apiUrls.auth.login, {
        email,
        password,
    });
    return data;
};

export const verifyJwt = async (token) => {
    const result = await api.get(apiUrls.auth.verifyJwt, {
        headers: {
            Authorization: `${token}`,
        },
    });
    return result;
};
