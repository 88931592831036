import { useEffect, useState } from 'react';
import { Button, Container, Form, Alert } from 'react-bootstrap';
import { authLogin } from '../../auth/auth/use-auth';
import logo from '../../images/logo.png';

const Login = ({ setIsAuthenticated }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {}, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage('');
        await authLogin({ email, password })
            .then((data) => {
                if (Object.keys(data).length > 0) {
                    // localstorage 토큰저장
                    localStorage.setItem('hpacsJwt', data.jwt);
                    setIsAuthenticated(true); // 인증 상태 변경
                }
            })
            .catch((error) => {
                setErrorMessage('Invalid email or password. Please try again.');
            });
    };

    return (
        <Container
            style={{
                maxWidth: '100%',
                height: '100vh',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '90vh',
                }}
            >
                <div
                    style={{
                        maxWidth: '350px',
                        margin: '20px',
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginBottom: '35px',
                        }}
                    >
                        <img src={logo} alt="Healthhub logo" />
                    </div>
                    {errorMessage && (
                        <Alert variant="danger">{errorMessage}</Alert>
                    )}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group
                            controlId="formBasicEmail"
                            style={{ marginBottom: '15px' }}
                        >
                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group
                            controlId="formBasicPassword"
                            style={{ marginBottom: '23px' }}
                        >
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Group>
                        <Button
                            variant="primary"
                            type="submit"
                            style={{
                                width: '100%',
                                padding: '8px 0',
                                backgroundColor: '#2d5082',
                                border: 'none',
                            }}
                        >
                            Login
                        </Button>
                    </Form>
                </div>
            </div>
        </Container>
    );
};

export default Login;
