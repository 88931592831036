const auth = {
    // post: 기본 로그인(email, password)
    login: '/auth/login/v1',
    // get: 인증 토큰 체크
    verifyJwt: '/auth/login/v1/check',
};

export const apiUrls = {
    auth,
};
